import generateAddendum from '@/mixins/final-note/addendums/Addendums'

export default {
  mixins: [generateAddendum],
  methods: {
    generateFinalNote() {
      let finalNote = `
        Referral Source Statement of Need: ${this.encounter.psychology.referral_source_statement_of_need}<br><br>
        History:<br>
        <ul>
          <li>Past Medical History: ${this.encounter.psychology.past_medical_history}</li>
          <li>Past Psychiatry History: ${this.encounter.psychology.past_psychiatry_history}</li>
          <li>Psychiatric Treatment History: ${this.encounter.psychology.psychiatric_treatment_history}</li>
        </ul>
        Medications:<br>
        <ul>
          <li>Relevant Non Psych Medications: ${this.encounter.psychology.relevant_non_psych_medications}</li>
          <li>Current Psych Medications: ${this.encounter.psychology.current_psych_medications}</li>
          <li>Medications, Allergies/Adverse Reactions: ${(this.encounter.psychology.adverse_reactions ? `Yes - ${this.encounter.psychology.adverse_reactions_yes}` : 'No')}</li>
          <li>Known Allergies/Adverse Reactions to Psychotropic Medications: ${(this.encounter.psychology.psychotropic_medication_reactions ? `Yes - ${this.encounter.psychology.psychotropic_medication_reactions_yes}` : 'No')}</li>
        </ul>
        Substance Abuse History:<br>
        <ul>
          <li>Substance Abuse History: ${this.encounter.psychology.substance_abuse_history}</li>
          <li>Substance Abuse Treatment History: ${(this.encounter.psychology.substance_abuse_treatment_history ? `Yes - ${this.encounter.psychology.substance_abuse_treatment_history_yes}` : 'No')}</li>
          <li>Recent (30 days)  Alcohol and Drug Use: ${(this.encounter.psychology.recent_alcohol_and_drug_use ? `Yes - ${this.encounter.psychology.recent_alcohol_and_drug_use_yes}` : 'No')}</li>
        </ul>
        Family and Social Status:<br>
        <ul>
          <li>Family Status: ${this.encounter.psychology.family_status}</li>
          <li>Social Status: ${this.encounter.psychology.social_status}</li>
        </ul>
        History of Present Illness: ${this.encounter.psychology.history_of_present_illness}<br><br>
        General Assessment/Mental Status:<br>
        <ul>
          <li>Appearance: ${this.encounter.psychology.appearance}</li>
          <li>Behaviors: ${this.$custom.toList(this.encounter.psychology.behaviors)}</li>
          <li>Speeches: ${this.$custom.toList(this.encounter.psychology.speeches)}</li>
          <li>Orientation: ${this.encounter.psychology.orientation}</li>
          <li>Memory: ${this.encounter.psychology.memory}</li>
          <li>Intellect: ${this.encounter.psychology.intellect}</li>
          <li>Concentration: ${this.encounter.psychology.concentration}</li>
          <li>Thought flows: ${this.$custom.toList(this.encounter.psychology.thought_flows)}</li>
          <li>Insight: ${this.encounter.psychology.insight}</li>
          <li>Moods: ${this.$custom.toList(this.encounter.psychology.moods)}</li>
          <li>Affects: ${this.$custom.toList(this.encounter.psychology.affects)}</li>
          <li>Hallucinations: ${(this.encounter.psychology.hallucinations ? `Yes - ${this.encounter.psychology.hallucinations_yes}` : 'No')}</li>
          <li>Delusions: ${(this.encounter.psychology.delusions ? `Yes - ${this.encounter.psychology.delusions_yes}` : 'No')}</li>
          <li>Homicidal: ${(this.encounter.psychology.homicidal ? `Yes - ${this.encounter.psychology.homicidal_yes}` : 'No')}</li>
          <li>Suicidal: ${(this.encounter.psychology.suicidal ? `Yes - ${this.encounter.psychology.suicidal_yes}` : 'No')}</li>
          <li>Judgement: ${this.encounter.psychology.judgement}</li>
        </ul>
        Diagnosis:<br>
        <ul>
          <li>Diagnosis: ${this.encounter.psychology.diagnosis}</li>
          <li>Previous Diagnosis: ${this.encounter.psychology.previous_diagnosis}</li>
          <li>Diagnostic Impression: ${this.encounter.psychology.diagnostic_impression}</li>
        </ul>
        Strengths &amp; Limitations:<br>
        <ul>
          <li>Strengths: ${this.$custom.toList(this.encounter.psychology.strengths, this.encounter.psychology.strengths_other)}</li>
          <li>Limitations: ${this.$custom.toList(this.encounter.psychology.limitations, this.encounter.psychology.limitations_other)}</li>
          <li>Impaired Functions: ${this.$custom.toList(this.encounter.psychology.impaired_functions, this.encounter.psychology.impaired_functions_other)}</li>
        </ul>
        Follow Up Plan:<br>
        <ul>
          <li>Plan/Recommendations: ${this.encounter.psychology.recommendations}</li>
          <li>Frequency of Follow Up Visits: ${this.encounter.psychology.frequency_of_follow_up_visits}</li>
          <li>Estimated Duration: ${this.encounter.psychology.estimated_duration}</li>
        </ul>
        Prognosis: ${this.encounter.psychology.prognosis}<br><br>
        Are Treatment Modifications Required For Cognitive Impairment: ${(this.encounter.psychology.treatment_modification_required_for ? `Yes - ${this.encounter.psychology.treatment_modification_required_for_yes}` : 'No')}
        `

      finalNote += this.generateFinalNoteAddendum()
      this.final_note = this.$custom.clean(finalNote, 'Psychology', false)
    },
  },
}
